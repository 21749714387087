var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrapper-box" },
    [
      _c("el-card", { staticClass: "my-order", attrs: { shadow: "hover" } }, [
        _c("div", { staticClass: "my-order" }, [
          _c("div", { staticClass: "right-box" }, [
            _c("table", { staticClass: "order-table" }, [
              _c("thead", [
                _c("th", [
                  [10].includes(_vm.orderObj.orderStatus)
                    ? _c(
                        "span",
                        {
                          staticClass: "t-btn-del",
                          on: { click: _vm.$showNoAuthMsg },
                        },
                        [_vm._v("取消订单")]
                      )
                    : _vm._e(),
                  _c("span", { staticClass: "t-order-span t-order-time" }, [
                    _vm._v("下单时间：" + _vm._s(_vm.orderObj.createTime)),
                  ]),
                  _c("span", { staticClass: "t-order-span t-order-id" }, [
                    _vm._v("订单号：" + _vm._s(_vm.orderObj.orderNo)),
                  ]),
                ]),
              ]),
              _c("tbody", [
                _c("td", { staticClass: "clearfix" }, [
                  _c("div", { staticClass: "f-left-box" }, [
                    _c("dl", { staticClass: "cus-form-item" }, [
                      _c("dt", { staticClass: "c-label" }, [
                        _vm._v("收件人："),
                      ]),
                      _c("dd", { staticClass: "c-item" }, [
                        _vm._v(_vm._s(_vm.orderObj.orderAddress.consigneeName)),
                      ]),
                    ]),
                    _c("dl", { staticClass: "cus-form-item" }, [
                      _c("dt", { staticClass: "c-label" }, [
                        _vm._v("联系方式："),
                      ]),
                      _c("dd", { staticClass: "c-item" }, [
                        _vm._v(
                          _vm._s(_vm.orderObj.orderAddress.consigneeMobile)
                        ),
                      ]),
                    ]),
                    _c("dl", { staticClass: "cus-form-item" }, [
                      _c("dt", { staticClass: "c-label" }, [
                        _vm._v("收货地址："),
                      ]),
                      _c("dd", { staticClass: "c-item" }, [
                        _vm._v(
                          _vm._s(_vm.orderObj.orderAddress.consigneeAddress)
                        ),
                      ]),
                    ]),
                  ]),
                  _c("div", { staticClass: "f-right-box" }, [
                    _c("dl", { staticClass: "cus-form-item" }, [
                      _c("dt", { staticClass: "c-label" }, [
                        _vm._v("商品合计："),
                      ]),
                      _c("dd", { staticClass: "c-item" }, [
                        _vm._v(
                          _vm._s(_vm.orderObj.goodsActualTotalAmounts / 100)
                        ),
                      ]),
                    ]),
                    _c("dl", { staticClass: "cus-form-item" }, [
                      _c("dt", { staticClass: "c-label" }, [_vm._v("邮费：")]),
                      _c("dd", { staticClass: "c-item" }, [
                        _vm._v(_vm._s(_vm.orderObj.freightAmounts / 100)),
                      ]),
                    ]),
                    _c("dl", { staticClass: "cus-form-item" }, [
                      _c("dt", { staticClass: "c-label" }, [
                        _vm._v("活动优惠："),
                      ]),
                      _c("dd", { staticClass: "c-item" }, [_vm._v("0")]),
                    ]),
                    _c("div", { staticClass: "order-pay-box" }, [
                      _c("dl", { staticClass: "cus-form-item" }, [
                        _c("dt", { staticClass: "c-label" }, [
                          _vm._v("应付："),
                        ]),
                        _c(
                          "dd",
                          {
                            staticClass: "c-item",
                            staticStyle: {
                              color: "#FF3C3E",
                              "font-size": "18px",
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.orderObj.orderActualTotalAmount / 100)
                            ),
                          ]
                        ),
                      ]),
                      _c("div", { staticClass: "c-btn-wrap" }, [
                        _vm.orderObj.orderStatus == 10
                          ? _c(
                              "span",
                              {
                                staticClass: "c-pay-btn",
                                on: { click: _vm.toPayFn },
                              },
                              [
                                _vm._v(
                                  "付款 " +
                                    _vm._s(
                                      _vm.orderObj.orderActualTotalAmount / 100
                                    )
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm.orderObj.orderStatus < 0
                          ? _c("span", { staticClass: "c-pay-btn no-pay" }, [
                              _vm._v(
                                "付款 " +
                                  _vm._s(
                                    _vm.orderObj.orderActualTotalAmount / 100
                                  )
                              ),
                            ])
                          : _vm._e(),
                      ]),
                    ]),
                  ]),
                ]),
              ]),
            ]),
            _c("table", { staticClass: "goods-table" }, [
              _c("colgroup", [
                _c("col"),
                _c("col", { staticStyle: { width: "100px" } }),
                _c("col", { staticStyle: { width: "80px" } }),
                _c("col", { staticStyle: { width: "100px" } }),
                _c("col", { staticStyle: { width: "80px" } }),
              ]),
              _c("thead", [
                _c("tr", [
                  _c(
                    "th",
                    { attrs: { colspan: "5" } },
                    [
                      _vm.orderObj.orderStatus < 0
                        ? _c(
                            "span",
                            {
                              staticClass: "t-btn-del",
                              on: { click: _vm.$showNoAuthMsg },
                            },
                            [_vm._v("再次购买")]
                          )
                        : _vm._e(),
                      _vm.orderObj.orderStatus == 20
                        ? _c(
                            "span",
                            {
                              staticClass: "t-btn-default",
                              on: { click: _vm.$showNoAuthMsg },
                            },
                            [_vm._v("催促发货")]
                          )
                        : _vm._e(),
                      _vm.orderObj.orderStatus == 30
                        ? _c(
                            "span",
                            {
                              staticClass: "t-btn-del",
                              on: { click: _vm.$showNoAuthMsg },
                            },
                            [_vm._v("确认收货")]
                          )
                        : _vm._e(),
                      _vm.orderObj.orderStatus == 40
                        ? _c(
                            "span",
                            {
                              staticClass: "t-btn-default",
                              on: { click: _vm.$showNoAuthMsg },
                            },
                            [_vm._v("联系客服")]
                          )
                        : _vm._e(),
                      _c("span", [_vm._v("商品清单")]),
                      [10].includes(_vm.orderObj.orderStatus)
                        ? [
                            _c("span", { staticClass: "order-status" }, [
                              _vm._v("待付款"),
                            ]),
                            _c("span", [
                              _vm._v(
                                "（请您尽快完成支付，该订单会为您保留2小时，如若到期未支付，该订单将会被系统自动取消）"
                              ),
                            ]),
                          ]
                        : _vm._e(),
                      _vm.orderObj.orderStatus < 0
                        ? [
                            _c("span", { staticClass: "order-status" }, [
                              _vm._v("已取消"),
                            ]),
                            _c("span", [
                              _vm._v(
                                "（退款会原路返回您的交易账户，如订单进行拆分，优惠券将不会返回）"
                              ),
                            ]),
                          ]
                        : _vm._e(),
                      [30].includes(_vm.orderObj.orderStatus)
                        ? [
                            _c("span", { staticClass: "order-status" }, [
                              _vm._v("待收货"),
                            ]),
                            _c("span", [
                              _vm._v(
                                "（您可在14天内对当前订单进行“确认收货”，届时如果您未确认订单也未发起售后申请，系统将在后台自动为您确认）"
                              ),
                            ]),
                          ]
                        : _vm._e(),
                      [20].includes(_vm.orderObj.orderStatus)
                        ? [
                            _c("span", { staticClass: "order-status" }, [
                              _vm._v("待发货"),
                            ]),
                            _c("span", [
                              _vm._v(
                                "（付款后如果平台没有在24小时内发货，您可以催促发货）"
                              ),
                            ]),
                          ]
                        : _vm._e(),
                      [40].includes(_vm.orderObj.orderStatus)
                        ? [
                            _c("span", { staticClass: "order-status" }, [
                              _vm._v("已完成"),
                            ]),
                            _c("span", [
                              _vm._v(
                                "（如果您对该笔交易有任何疑问，请您联系客服进行解决）"
                              ),
                            ]),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                ]),
              ]),
              _c("tbody", { staticClass: "t-c-thead" }, [
                _c("tr", [
                  _c("td", [_vm._v("商品信息")]),
                  _c("td", [_vm._v("单价")]),
                  _c("td", [_vm._v("数量")]),
                  _c("td", [_vm._v("实付")]),
                  _c("td", [_vm._v("会员积分")]),
                ]),
              ]),
              _c(
                "tbody",
                { staticClass: "t-c-tbody" },
                _vm._l(
                  _vm.orderObj.orderGoodsList,
                  function (goodsItem, index) {
                    return _c("tr", { key: index }, [
                      _c("td", [
                        _c("img", {
                          staticClass: "p-img",
                          attrs: { src: goodsItem.picture },
                        }),
                        _c("div", { staticClass: "p-sub" }, [
                          _c("h3", [_vm._v(_vm._s(goodsItem.goodsTitle))]),
                          _c(
                            "p",
                            { staticClass: "p-attr" },
                            _vm._l(
                              goodsItem.attributePairs,
                              function (attrItem, ind) {
                                return _c("span", { key: ind }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        (ind ==
                                          goodsItem.attributePairs.length - 1 &&
                                          attrItem.attributeValue) ||
                                          attrItem.attributeValue + "； "
                                      ) +
                                      " "
                                  ),
                                ])
                              }
                            ),
                            0
                          ),
                        ]),
                      ]),
                      _c("td", [
                        _vm._v("¥" + _vm._s(goodsItem.saleUnitPrice / 100)),
                      ]),
                      _c("td", [_vm._v(_vm._s(goodsItem.goodsNum))]),
                      _c("td", [
                        _vm._v("¥" + _vm._s(goodsItem.actualTotalAmount / 100)),
                      ]),
                      _c("td", [_vm._v("0")]),
                    ])
                  }
                ),
                0
              ),
            ]),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }