import { render, staticRenderFns } from "./OrderInfor.vue?vue&type=template&id=39a50c48&scoped=true"
import script from "./OrderInfor.vue?vue&type=script&lang=js"
export * from "./OrderInfor.vue?vue&type=script&lang=js"
import style0 from "./OrderInfor.vue?vue&type=style&index=0&id=39a50c48&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "39a50c48",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/project/project_code/tea/frontend/tarena-tp-tea-front/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('39a50c48')) {
      api.createRecord('39a50c48', component.options)
    } else {
      api.reload('39a50c48', component.options)
    }
    module.hot.accept("./OrderInfor.vue?vue&type=template&id=39a50c48&scoped=true", function () {
      api.rerender('39a50c48', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/mall/order/OrderInfor.vue"
export default component.exports